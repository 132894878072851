$blue: #7989ff !default;
$green: #34bc3f !default;
$cyan: #4191eb !default;
$yellow: #fc7721 !default;
$red: #f31b48 !default;

$gray-100: #f7f8f9 !default;
$gray-200: #f2f2f2 !default;
$gray-300: #ebebeb !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #a1a1a1 !default;
$gray-700: #495057 !default;
$gray-800: #222222 !default;
$gray-900: #212529 !default;

$secondary: #ffffff !default;

$link-color: #3d54ff !default;

$font-family-sans-serif: "Segoe UI", "Roboto", "Helvetica Neue", "-apple-system", "BlinkMacSystemFont", "Arial", "Noto Sans", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-size-base: 0.88rem !default;

$min-contrast-ratio: 2.45 !default;

// Custom Variables

$theme-font-weight-semibold: 600 !default;

$theme-border-radius: 3em !default;
$theme-border-radius-sm: 3px !default;
$theme-border-radius-xs: 2px !default;

$theme-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !default;
$theme-box-shadow-xs: 0px 1px 2px 0px rgba(0, 0, 0, 0.1) !default;
$theme-box-shadow-sm: 0px 2px 4px 0px rgba(0, 0, 0, 0.2) !default;
$theme-box-shadow-lg: 0px 2px 6px 0px rgba(0, 0, 0, 0.3) !default;


// Breadcrumbs

$breadcrumb-border-radius: $theme-border-radius-sm !default;


// Buttons + Forms

$btn-padding-y: 0.4rem !default;
$btn-padding-x: .75rem !default;

$btn-box-shadow: $theme-box-shadow !default;
$btn-active-box-shadow: $theme-box-shadow !default;
$btn-font-weight: $theme-font-weight-semibold !default;
$btn-border-radius: $theme-border-radius !default;
$btn-border-radius-lg: $theme-border-radius !default;
$btn-border-radius-sm: $theme-border-radius !default;


// Cards

$card-border-radius: $theme-border-radius-sm !default;


// Checkboxes

$custom-checkbox-indicator-border-radius: $theme-border-radius-xs !default;
$custom-control-indicator-bg-size: 100% 100% !default;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPSczLjMnIGZpbGw9JyNmZmZmZmYnLz48Y2lyY2xlIHI9JzIuMycgZmlsbD0nIzc5ODlmZicvPjwvc3ZnPg==") !default;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-2 -2 12 12'%3E%3Cpath fill='%23fff' d='M7,1L3,5L1,3L0,4l3,3l5-5L7,1z'/%3E%3C/svg%3E") !default;
$custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 12 12' style='enable-background:new 0 0 12 12;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Crect id='_x31_' x='2' y='5' class='st0' width='8' height='2'/%3E%3C/svg%3E%0A") !default;

// Switches

$custom-switch-width: 1.875rem !default;
$custom-switch-indicator-size: 1.25rem !default;

// Dropdowns

$dropdown-item-padding-y: 0.2rem !default;
$dropdown-item-padding-x: 1rem !default;


// Forms

$input-bg: rgba(0, 0, 0, 0.08) !default;
$input-disabled-bg: rgba(0, 0, 0, 0.03) !default;
$input-border-width: 2px !default;
$input-btn-padding-x: 0.6rem !default;
$input-btn-padding-y: 0.375rem !default;
$input-border-color: rgba(0, 0, 0, 0.3) !default;
$input-focus-border-color: $blue !default;
$input-focus-width: 0 !default;
$input-group-addon-bg: transparent !default;
$input-group-addon-border-color: transparent !default;

$input-border-radius: 2px !default;
$input-border-radius-lg: $input-border-radius !default;
$input-border-radius-sm: $input-border-radius !default;

$input-box-shadow: none !default;
$input-focus-box-shadow: none !default;

$custom-select-border-width: 2px !default;
$custom-select-box-shadow: none !default;
$custom-select-border-radius: $input-border-radius !default;
$custom-select-bg: rgba(0, 0, 0, 0.08) !default;
$custom-select-disabled-bg: rgba(0, 0, 0, 0.03) !default;

$custom-file-border-width: 2px !default;
$custom-file-box-shadow: none !default;
$custom-file-focus-box-shadow: none !default;
$custom-file-bg: rgba(0, 0, 0, 0.08) !default;
$custom-file-button-bg: transparent !default;


// Jumbotron

$jumbotron-padding: 1.5rem !default;


// List group

$list-group-item-padding-y: 0.65rem !default;
$list-group-border-radius: $theme-border-radius-xs !default;


// Modals

$modal-content-border-radius: $theme-border-radius-sm !default;
$modal-content-border-color: white !default;
$modal-content-box-shadow-xs: $theme-box-shadow-lg !default;
$modal-content-box-shadow-sm-up: $theme-box-shadow-lg !default;
$modal-header-padding: 0.75rem !default;
$modal-inner-padding: 0.75rem !default;


// Navs

$nav-pills-border-radius: $theme-border-radius !default;
$nav-tabs-border-width: 0px !default;
$nav-tabs-link-active-border-color: $blue !default;
$nav-tabs-link-hover-border-color: $blue !default;
$nav-divider-margin-y: 0.25rem !default;


// Pagination

$pagination-color: #444444 !default;
$pagination-hover-color: darken($pagination-color, 15%) !default;
$pagination-bg: $gray-200 !default;
$pagination-border-width: 0 !default;
$pagination-bg: transparent !default;
$pagination-disabled-bg: transparent !default;
$pagination-padding-y: 0.5rem !default;
$pagination-padding-x: 0.5rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.25rem !default;
$pagination-padding-y-lg: 1rem !default;
$pagination-padding-x-lg: 1rem !default;

// Popovers

$popover-box-shadow: $theme-box-shadow !default;
$popover-border-radius: $theme-border-radius-sm !default;
$popover-header-padding-x: 1rem !default;
$popover-body-padding-y: 0.75rem !default;


// Progress bars

$progress-height: 1rem !default;
$progress-border-radius: 0 !default;
$progress-box-shadow: none !default;


// Tables

$table-cell-padding-sm: 0.3rem 0.5rem !default;
