$dx-primary: #7989ff !default;
$dx-secondary: #444444 !default;
$dx-danger: #f31b48 !default;
$dx-success: #34bc3f !default;
$dx-info: #4191eb !default;
$dx-focus: #a780cd !default;

$dx-gray-100: #f7f8f9 !default;
$dx-gray-200: #f2f2f2 !default;
$dx-gray-300: #ebebeb !default;
$dx-gray-400: #ced4da !default;
$dx-gray-500: #adb5bd !default;
$dx-gray-600: #a1a1a1 !default;
$dx-gray-700: #495057 !default;
$dx-gray-800: #222222 !default;
$dx-gray-900: #212529 !default;

$dx-min-contrast-ratio: 2.45 !default;

$dx-btn-padding-x-ratio: 3 !default;

// Common
$dx-color: $dx-gray-900 !default;

// Button
$dx-btn-border-width: 2px !default;
$dx-border-color: rgba(0, 0, 0, 0.125) !default;
$dx-btn-border-radius: $dx-round-border-radius !default;
$dx-btn-border-radius-sm: $dx-round-border-radius !default;
$dx-btn-border-radius-lg: $dx-round-border-radius !default;
$dx-btn-box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 30%) !default;

$dx-btn-size-box-correction: 0.0625rem !default;

// Toolbar
$dx-toolbar-btn-border-radius: $dx-round-border-radius !default;
$dx-toolbar-btn-border-radius-sm: $dx-round-border-radius !default;
$dx-toolbar-btn-border-radius-lg: $dx-round-border-radius !default;

// TextEdit
$dx-text-edit-bg: $dx-gray-200 !default;
$dx-text-edit-border-width: 2px !default;
$dx-text-edit-focus-shadow-size: 0px !default;

$dx-text-edit-btn-bg: $dx-gray-300 !default;
$dx-text-edit-btn-color: rgba(0, 0, 0, 0.6) !default;
$dx-text-edit-btn-hover-color: rgba(0, 0, 0, 0.6) !default;

// Pager
$dx-pager-bg: $dx-gray-300 !default;
$dx-pager-border-radius: $dx-round-border-radius !default;
$dx-pager-border-radius-sm: $dx-round-border-radius !default;
$dx-pager-border-radius-lg: $dx-round-border-radius !default;

$dx-pager-padding-x: 0.3175rem !default;
$dx-pager-padding-y: 0.3175rem !default;
$dx-pager-padding-x-sm: 0.25rem !default;
$dx-pager-padding-y-sm: 0.25rem !default;
$dx-pager-padding-x-lg: 0.375rem !default;
$dx-pager-padding-y-lg: 0.375rem !default;

$dx-pager-btn-bg: transparent !default;
$dx-pager-btn-border-color: transparent !default;
$dx-pager-btn-hover-bg: transparent !default;
$dx-pager-btn-disabled-bg: transparent !default;

$dx-pager-page-edit-bg: transparent !default;

// Tabs
$dx-tabs-separator-border-width: 2px !default;
$dx-tabs-tab-selected-element-height: 2px !default;
